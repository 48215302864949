<template>
  <div :class='extraClass'>
    <HeaderAll />
    <main>
      <slot name="body_page"></slot>
    </main>
  </div>
</template>

<script>
import { inject } from 'vue'
import HeaderAll from '~/components/globals/Header/HeaderAll'

export default {
  name: 'NoFooterLayout',
  components: {
    HeaderAll
  },
  props: {
    extraClass: {
      type: String,
      default: ''
    }
  },
  setup() {
    const apiLoaded = inject('pageLoaded', true)
    return { apiLoaded }
  }
}
</script>

<style scoped>

</style>
